import { defineStore } from "pinia";
import { useStrapi } from "@/composable/useStrapi";

const PAGE_SIZE = 60;
export const useNotifications = defineStore("notification", {
  state: () => ({
    list: [],
    unread: 0, // count unread notifications
    pagination: {
      pageSize: PAGE_SIZE,
      page: 1,
    },
    mayHasMore: true,
    shouldGo: null,
  }),

  actions: {
    async search() {
      this.mayHasMore = true;
      this.pagination = {
        pageSize: PAGE_SIZE,
        page: 1,
      };
      this.list = await this.findNotifications();
    },
    async findNotifications() {
      const { find } = useStrapi();
      const res = await find(`notifications`, {
        pagination: this.pagination,
        sort: { read: "asc", createdAt: "desc" },
      });
      if (res && res.data && res.data.length < this.pagination.pageSize) {
        this.mayHasMore = false;
      }
      return (
        res?.data?.map((n) => {
          return { ...(n.attributes || {}), id: n.id };
        }) || []
      );
    },
    async more() {
      this.pagination.page++;
      const nextEntries = await this.findNotifications();
      this.list.push(...nextEntries);
    },
    async count() {
      // refresh count
      const { client, user } = useStrapi();
      if (user.value) {
        const res = await client(`/notification/unread`);
        this.unread = res || 0;
      }
    },
    async find(id) {
      // when native notif is tapped, we have its id, find it
      const { findOne } = useStrapi();
      const res = await findOne(`notifications`, id);
      return res;
    },
    getPathOfNotif(notif) {
      // notification is tapped (from native or app)
      if (notif) {
        if (notif.type === "TASK" && notif.data?.aquarium_id) {
          return "/aquarium/" + notif.data?.aquarium_id + "/tasks";
        }
        if (notif.type === "DM" && notif.data?.conversation_id) {
          return "/aquarium/dm/" + notif.data?.conversation_id;
        }
        if (
          notif.type === "MODERATION" &&
          notif.data?.ad_id &&
          notif.data?.ad_token
        ) {
          return "/ads/" + notif.data?.ad_token;
        }
        if (notif.type === "ADS" && notif.data?.ad_id) {
          return "/myads/" + notif.data?.ad_id + "/status";
        }
        if (notif.type === "PURCHASE" && notif.data?.ad_id) {
          return "/myads/" + notif.data?.ad_id + "/options";
        }
        if (notif.type === "MODERATION_RATING" && notif.data?.rating_id) {
          return "/ads-moderation/1";
        }
        if (notif.type === "RATING" && notif.data?.user_id) {
          return "/ratings/" + notif.data?.user_id;
        }
      }
      return null;
    },
    async markRead(ids) {
      // notifications are displayed to user
      const { client } = useStrapi();
      const res = await client(`/notification/markRead`, {
        method: "POST",
        data: { notification_ids: ids },
      });
      if (res) {
        this.list.forEach((n) => {
          if (ids.includes(n.id)) {
            n.read = true;
          }
        });
        this.unread -= ids.length;
        if (this.unread < 0) this.unread = 0;
      }
    },
    async readTypeRef(type, refId) {
      // notifications are displayed to user
      const { client } = useStrapi();
      const res = await client(`/notification/markRead`, {
        method: "POST",
        data: { byref: { type, refId } },
      });
      if (res) {
        this.list.forEach((n) => {
          if (n.type == type && n.ref_id == refId) {
            n.read = true;
            this.unread--;
          }
        });
        if (this.unread < 0) this.unread = 0;
      }
    },
    async markAllRead() {
      // notifications are displayed to user
      const { client } = useStrapi();
      const res = await client(`/notification/markRead`, {
        method: "POST",
        data: { all: true },
      });
      if (res) {
        this.list.forEach((n) => {
          n.read = true;
        });
        this.unread = 0;
      }
    },
  },
});
