// index.js
import en from "./en.json";
import fr from "./fr.json";
//import de from "./de.json";
//import es from "./es.json";
//import it from "./it.json";
import th from "./th.json";
import uk from "./uk.json";

export const defaultLocale = "en";

export const languages = {
  en,
  fr,
  //de,
  //es,
  //it,
  th,
  uk,
};
