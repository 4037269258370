import { ref } from "vue";

// this counter counts the number of time app is reactivated
// if a specific page wants to know if app has become active, it listens to this counter
// each time it changes, app has become active
const counter = ref(1);
const inactiveCounter = ref(1);

export const useApp = () => {
  const setActive = () => {
    console.log("setActive");
    counter.value++;
  };
  const setInactive = () => {
    console.log("setInactive");
    inactiveCounter.value++;
  };
  return {
    counter,
    inactiveCounter,
    setActive,
    setInactive,
  };
};
