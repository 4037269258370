import { useStorage } from "@/composable/useStorage";
import { useStrapi } from "@/composable/useStrapi";
import { useApp } from "@/composable/useApp";
import { ref, toRaw, watch } from "vue";

const SEND_IF_NB_KEYS_MAX = 100; // max number of ads which have new stats, send it
const SEND_AFTER_TIMEOUT = 30 * 1000; // timeout after which stats is send if there is no new stats (user is doing smthg else)
const stats = ref({}) /* Stats is volatile and reset every 5 minutes */,
  sessionstats = ref(
    {}
  ) /* Persists for the session (not stored) to keep track of unique views / opens during this session, allows to count views / open only once a session for a user */,
  uniquestats = ref({}) /* Persists to keep track of unique views / opens */,
  currentStatsKeys = ref(new Set()),
  timeoutSend = ref();
export const useAdStats = () => {
  /** Send current stats to server to increment ads stats in db */
  const sendStats = async () => {
    //console.log("should send stats", currentStatsKeys.value);
    if (currentStatsKeys.value.size === 0) return;
    const { client } = useStrapi();
    // copy stats obj, reset it
    const cp = JSON.parse(JSON.stringify(stats.value));
    stats.value = {};
    currentStatsKeys.value = new Set();
    const store = useStorage();
    store.setAdStats(toRaw(stats.value), toRaw(uniquestats.value));
    // send copied object
    try {
      const res = await client(`/classified-ads/stats`, {
        method: "POST",
        data: {
          ...cp,
        },
      });
      //console.log("send stats done", res);
    } catch (e) {
      // if fail, merge copied object in new one
      Object.keys(cp).forEach((k) => {
        if (!stats.value[k]) stats.value[k] = cp[k];
        else {
          Object.keys(cp[k]).forEach((adId) => {
            if (!stats.value[k][adId]) stats.value[k][adId] = cp[k][adId];
            else {
              stats.value[k][adId].nb += cp[k][adId].nb;
            }
          });
        }
      });
      currentStatsKeys.value = new Set(
        Object.keys(stats.value).flatMap((k) =>
          Number(Object.keys(stats.value[k]))
        )
      );
      const store = useStorage();
      store.setAdStats(toRaw(stats.value), toRaw(uniquestats.value));
    }
  };

  /** Called when storage inits, restore old stats value */
  const initStats = async (s, us) => {
    stats.value = s || {};
    currentStatsKeys.value = new Set(
      Object.keys(stats.value).flatMap((k) =>
        Number(Object.keys(stats.value[k]))
      )
    );
    uniquestats.value = us || {};
    sessionstats.value = {};

    /** send stats when app becomes inactive */
    const { inactiveCounter } = useApp();
    watch(
      () => inactiveCounter.value,
      () => {
        sendStats();
      }
    );
  };

  /** Increment a key is stats */
  const incKey = (key, adId, incOncePerSession = false) => {
    let hasIncremented = false;
    if (incOncePerSession) {
      // current stat must be incremented only once per session for the metric, check if session stats contains this ad for this metric
      if (!sessionstats.value[key]) sessionstats.value[key] = [];
      if (!sessionstats.value[key].includes(adId)) {
        // session stats does not contain this ad for this metric, add it
        sessionstats.value[key].push(adId);

        // count inc metric
        if (!stats.value[key]) stats.value[key] = {};
        if (!stats.value[key][adId]) stats.value[key][adId] = { nb: 0 };
        stats.value[key][adId].nb++;
        hasIncremented = true;
      }
    } else {
      if (!stats.value[key]) stats.value[key] = {};
      if (!stats.value[key][adId]) stats.value[key][adId] = { nb: 0 };
      stats.value[key][adId].nb++;
      hasIncremented = true;
    }

    if (!uniquestats.value[key]) uniquestats.value[key] = [];
    if (!uniquestats.value[key].includes(adId)) {
      // unique stats does not contain this ad for this metric, add it
      uniquestats.value[key].push(adId);

      if (hasIncremented) {
        // let serveur knows that there is a new unique event for metric
        if (!stats.value["unique_" + key]) stats.value["unique_" + key] = [];
        stats.value["unique_" + key].push(adId);
      }
    }

    const store = useStorage();
    store.setAdStats(toRaw(stats.value), toRaw(uniquestats.value));

    if (hasIncremented) {
      // has incremented -> add to currentStatsKeys
      currentStatsKeys.value.add(adId);
      if (currentStatsKeys.value.size >= SEND_IF_NB_KEYS_MAX) {
        // send immediately if number of ads which have new stats reached max
        sendStats();
      } else {
        // set a timeout to send stats if no stats activity
        clearTimeout(timeoutSend.value);
        timeoutSend.value = setTimeout(() => {
          sendStats();
        }, SEND_AFTER_TIMEOUT);
      }
    }
  };

  const viewAd = (adId) => {
    // we count views once a session (app closed / reopened)
    incKey("view", adId, true);
  };

  const openAd = (adId) => {
    incKey("open", adId);
  };

  const openPortalAd = (adId) => {
    incKey("open_shared", adId);
    sendStats();
  };

  const shareAd = (adId) => {
    incKey("share", adId);
  };

  return {
    initStats,
    viewAd,
    openAd,
    shareAd,
    openPortalAd,
  };
};
