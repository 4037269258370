<template>
  <div class="scrollable-div" ref="tabbar" :class="{ left: $props.left }">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";

const props = defineProps({
  left: Boolean,
});
const mouseDown = ref(false);
const startX = ref(),
  scrollLeft = ref();
const tabbar = ref();

const startDragging = (e) => {
  mouseDown.value = true;
  startX.value = e.pageX - tabbar.value.offsetLeft;
  scrollLeft.value = tabbar.value.scrollLeft;

  e.preventDefault();
};

const stopDragging = (e) => {
  mouseDown.value = false;
  e.preventDefault();
};

const move = (e) => {
  e.preventDefault();
  if (!mouseDown.value) {
    return;
  }
  const x = e.pageX - tabbar.value.offsetLeft;
  const scroll = x - startX.value;
  tabbar.value.scrollLeft = scrollLeft.value - scroll;
  e.preventDefault();
};

onMounted(() => {
  // Add the event listeners
  tabbar.value.addEventListener("mousemove", move, false);
  tabbar.value.addEventListener("mousedown", startDragging, false);
  tabbar.value.addEventListener("mouseup", stopDragging, false);
  tabbar.value.addEventListener("mouseleave", stopDragging, false);
});
</script>
