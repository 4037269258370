<template>
  <div
    :class="{
      'bg-nomoonnight rounded': !props.light && !props.light2,
      'bg-deepdarkblue rounded-sm': props.light,
      'bg-deepblue rounded-sm': props.light2,
    }"
  >
    <div
      class="px-4 flex items-center"
      :class="{ 'py-2': !props.largeHeader, 'py-4': props.largeHeader }"
      @click="() => update(!props.modelValue)"
    >
      <div class="flex-grow">
        <slot name="header"></slot>
      </div>

      <div
        class="shrink-0 flex items-center justify-center"
        v-if="!props.forceOpened"
      >
        <ion-icon
          :icon="$icons.caretDown"
          class="text-xl text-right font-bold -mb-[3px]"
        ></ion-icon>
      </div>
    </div>
    <transition name="fadeHeight">
      <div
        v-show="props.modelValue || props.forceOpened"
        :class="{ 'm-4 mt-0': !props.bodyNoMargin }"
      >
        <slot></slot>
        <div class="h-4 w-full"></div>
      </div>
    </transition>
  </div>
</template>
<script setup>
const props = defineProps({
  modelValue: Boolean,
  light: Boolean,
  light2: Boolean,
  bodyNoMargin: Boolean,
  forceOpened: Boolean,
  largeHeader: Boolean,
});

const emit = defineEmits(["update:modelValue"]);
const update = (val) => {
  if (props.forceOpened) return;
  emit("update:modelValue", val);
};
</script>

<style scoped>
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.25s;
  max-height: 500px;
  overflow: hidden;
}
.fadeHeight-enter-from,
.fadeHeight-leave-to {
  max-height: 0px;
}
</style>
