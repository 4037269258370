import { defineStore } from "pinia";
import { Storage } from "@ionic/storage";
import { useStrapi } from "@/composable/useStrapi";
import { useAdStats } from "@/composable/useAdStats";
import { useSettings } from "@/store/useSettings.js";
import { useCategories } from "@/store/useCategories.js";
import { useAquariums } from "@/store/useAquariums.js";
import { useEntries } from "@/store/useEntries.js";
import { useMeasures } from "@/store/useMeasures";
import { Capacitor } from "@capacitor/core";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { toRaw, isRef, isReactive, isProxy } from "vue";

export function deepToRaw(sourceObj) {
  const objectIterator = (input) => {
    if (Array.isArray(input)) {
      return input.map((item) => objectIterator(item));
    }
    if (isRef(input) || isReactive(input) || isProxy(input)) {
      return objectIterator(toRaw(input));
    }
    if (input && typeof input === "object") {
      return Object.keys(input).reduce((acc, key) => {
        acc[key] = objectIterator(input[key]);
        return acc;
      }, {});
    }
    return input;
  };

  return objectIterator(sourceObj);
}

let store,
  initialized = false;

/**
 * If you add languages :
 *
 * Change in Backoffice to be sure it can be added in translate / in api to generate collection files for this api
 * -> Regenerate collection files
 * -> Generate MR for app, merge and pull it
 * Change in main.ts -> dayjs locales load
 * Change in src/locale/index.js to load translation file
 */
export const SUPPORTED_LANGS = [
  "en",
  "fr",
  //"es",
  "th",
  //"it",
  //"de",
  //"pt",
  //"zh",
  //"ar",
  //"el",
  //"he",
  //"ja",
  "uk",
];

export const useStorage = defineStore("blue", {
  state: () => {
    return {
      token: null,
      locale: null,
      coldata: null,
      errorWhileLoading: false,
      currentAquarium: null,
      bubble: 0,
      elementProducts: {},
      loadingCollection: false,
    };
  },
  actions: {
    setBubble(nb) {
      this.bubble = nb;
      store.set("bubble", nb);
    },
    setToken(t) {
      this.token = t;
      store.set("token", t);
    },
    setAdStats(stats, uniqueStats) {
      store.set("adStats", stats);
      store.set("adUniqueStats", uniqueStats);
    },
    async setLocale(l) {
      if (Capacitor.getPlatform() !== "web") {
        try {
          if (this.locale && this.locale != l) {
            // unsubscribe from this language topic
            await FirebaseMessaging.unsubscribeFromTopic({
              topic: "all_" + this.locale,
            });
          }
          await FirebaseMessaging.subscribeToTopic({ topic: "all_" + l });
        } catch (e) {
          console.error("error while subscribing to topics");
          console.error(e);
        }
        try {
          await FirebaseAnalytics.setUserProperty({
            name: "language",
            value: l,
          });
        } catch (e) {
          console.error("error while setting user property language");
          console.error(e);
        }
      }
      this.locale = l;
      store.set("locale", l);
    },
    setColdata(cd) {
      this.coldata = cd;
      store.set("coldata", cd);
    },
    async checkColDataAndDownload(
      cb = () => {
        // nothing
      },
      wait = false,
      key = null
    ) {
      let shouldDownload = false;
      if (!this.coldata) {
        shouldDownload = true;
      }
      if (this.locale != this.coldata?.lang) {
        shouldDownload = true;
      }
      const currentVersion = this.coldata?.version || 0;
      // get latest version for current language
      const ts = {
        filters: {
          lang: this.locale,
        },
        pagination: { pageSize: 1 },
        populate: ["file"],
        sort: { version: "desc" },
      };
      if (key) {
        ts.filters.key = key;
      }
      const { user } = useStrapi();
      const isBetaUser = user.value?.is_admin || user.value?.is_beta;
      if (isBetaUser) {
        ts.sort = { createdAt: "desc" };
      }
      try {
        const { client } = useStrapi();
        const res = await client("/collection-cache-files", {
          params: { ...ts },
        });
        if (
          res?.data &&
          res.data[0] &&
          res.data[0].attributes?.version != currentVersion &&
          (!isBetaUser || res.data[0].attributes?.version != -currentVersion)
        ) {
          shouldDownload = true;
        }

        // uncomment to display loading collection popup for 5 secs
        /*cb(true);
        await new Promise((res) => setTimeout(res, 5000));
        cb(false);*/
        if (shouldDownload) {
          const sttime = new Date().getTime();
          cb(true);
          this.loadingCollection = true;
          //await new Promise((res) => setTimeout(res, 5000));
          // download latest
          const coldata = await fetch(
            res.data[0].attributes?.file?.data?.attributes?.url
          );
          if (coldata.ok) {
            const newcoldata = await coldata.json();
            newcoldata.lang = this.locale;
            this.setColdata(newcoldata);
            this.initCollection();
            this.loadingCollection = false;
          } else {
            this.errorWhileLoading = true;
            console.log("an error occurred while fetching collection data");
            this.loadingCollection = false;
            cb(false);
            return;
          }
          // if wait, minimum display time is 3 secs
          const execTime = new Date().getTime() - sttime;
          if (wait && execTime < 3000) {
            await new Promise((res) => setTimeout(res, 3000 - execTime));
          }
          cb(false);
        }
        this.errorWhileLoading = false;
      } catch (e) {
        this.errorWhileLoading = true;
        console.log("an error occurred while fetching collection data");
        console.error(e);
      }
    },
    initCollection() {
      if (this.coldata != null) {
        const categories = useCategories();
        const settings = useSettings();
        const entries = useEntries();
        const measures = useMeasures();

        settings.loadSettings();
        categories.loadCategories();
        entries.loadEntries();
        measures.loadConfig(true);
      }
    },
    setCurrentAquarium(id) {
      this.currentAquarium = id;
      store.set("currentAquarium", id);
    },
    setElementsProducts(elProds) {
      this.elementProducts = elProds;
      store.set("elementProducts", elProds);
    },
    setElementProduct(elId, productId) {
      if (!this.elementProducts) this.elementProducts = {};
      this.elementProducts[elId] = { productId };
      store.set("elementProducts", deepToRaw(this.elementProducts));
    },
    setElementFullObject(elId, obj) {
      if (!this.elementProducts) this.elementProducts = {};
      this.elementProducts[elId] = { ...obj };
      store.set("elementProducts", deepToRaw(this.elementProducts));
    },
  },
});

export const initStorage = async () => {
  if (initialized) return;
  // create store
  store = new Storage({
    name: "__bluedb",
  });
  await store.create();

  // init values
  const token = await store.get("token"),
    locale = await store.get("locale"),
    coldata = await store.get("coldata"),
    curAq = await store.get("currentAquarium"),
    curBubble = await store.get("bubble"),
    curElProds = await store.get("elementProducts"),
    stats = await store.get("adStats"),
    uniqueStats = await store.get("adUniqueStats");

  const storage = useStorage();
  storage.setToken(token);
  await storage.setLocale(locale);
  storage.setColdata(coldata);
  storage.setCurrentAquarium(curAq);
  storage.initCollection();

  const aquariums = useAquariums();
  aquariums.setCurrent(curAq);

  /* Set ad stats */
  const adStatsComp = useAdStats();
  adStatsComp.initStats(stats, uniqueStats);

  storage.setBubble(curBubble);
  storage.setElementsProducts(curElProds);

  initialized = true;
};
